@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "roboto", sans-serif;
}

input{
  background-color: inherit;
}

.editmodal input{
  margin-left: 1em;
}